/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * urw-din:
 *   - http://typekit.com/eulas/00000000000000007735a839
 *   - http://typekit.com/eulas/00000000000000007735a83d
 *   - http://typekit.com/eulas/00000000000000007735a84b
 *   - http://typekit.com/eulas/00000000000000007735a84f
 * urw-din-condensed:
 *   - http://typekit.com/eulas/00000000000000007735a829
 *   - http://typekit.com/eulas/00000000000000007735a82c
 *   - http://typekit.com/eulas/00000000000000007735a82e
 *   - http://typekit.com/eulas/00000000000000007735a833
 *   - http://typekit.com/eulas/00000000000000007735a835
 *   - http://typekit.com/eulas/00000000000000007735a838
 *   - http://typekit.com/eulas/00000000000000007735a83b
 *   - http://typekit.com/eulas/00000000000000007735a83e
 *   - http://typekit.com/eulas/00000000000000007735a841
 *   - http://typekit.com/eulas/00000000000000007735a844
 *   - http://typekit.com/eulas/00000000000000007735a847
 *   - http://typekit.com/eulas/00000000000000007735a84a
 *   - http://typekit.com/eulas/00000000000000007735a84d
 *   - http://typekit.com/eulas/00000000000000007735a850
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-01-22 23:07:59 UTC"}*/
html, body {
    margin: 0;
    height: 100%;
    background: black;
}
#root {
  height: 100%;
}

@import url("https://p.typekit.net/p.css?s=1&k=ohh8hep&ht=tk&f=36601.36602.36607.36608.36611.36612.36613.36614.36615.36616.36617.36618.36619.36620.36621.36622.36623.36624&a=38677799&app=typekit&e=css");

@font-face {
  font-family: "urw-din";
  src: url("https://use.typekit.net/af/1f0265/00000000000000007735a839/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/1f0265/00000000000000007735a839/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/1f0265/00000000000000007735a839/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "urw-din";
  src: url("https://use.typekit.net/af/f1d51b/00000000000000007735a83d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/f1d51b/00000000000000007735a83d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/f1d51b/00000000000000007735a83d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "urw-din";
  src: url("https://use.typekit.net/af/1c5092/00000000000000007735a84b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/1c5092/00000000000000007735a84b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/1c5092/00000000000000007735a84b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "urw-din";
  src: url("https://use.typekit.net/af/cd97e9/00000000000000007735a84f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/cd97e9/00000000000000007735a84f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/cd97e9/00000000000000007735a84f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/a85a9c/00000000000000007735a829/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/a85a9c/00000000000000007735a829/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3")
      format("woff"),
    url("https://use.typekit.net/af/a85a9c/00000000000000007735a829/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/4b35df/00000000000000007735a82c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/4b35df/00000000000000007735a82c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3")
      format("woff"),
    url("https://use.typekit.net/af/4b35df/00000000000000007735a82c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 100;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/9ad1b7/00000000000000007735a82e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9ad1b7/00000000000000007735a82e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9ad1b7/00000000000000007735a82e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/2c4fc2/00000000000000007735a833/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/2c4fc2/00000000000000007735a833/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3")
      format("woff"),
    url("https://use.typekit.net/af/2c4fc2/00000000000000007735a833/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/c5d8cc/00000000000000007735a835/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c5d8cc/00000000000000007735a835/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c5d8cc/00000000000000007735a835/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/9450d7/00000000000000007735a838/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9450d7/00000000000000007735a838/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9450d7/00000000000000007735a838/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/b27e1e/00000000000000007735a83b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b27e1e/00000000000000007735a83b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b27e1e/00000000000000007735a83b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/ba5c17/00000000000000007735a83e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ba5c17/00000000000000007735a83e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ba5c17/00000000000000007735a83e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/06eb22/00000000000000007735a841/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/06eb22/00000000000000007735a841/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/06eb22/00000000000000007735a841/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/413a03/00000000000000007735a844/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/413a03/00000000000000007735a844/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/413a03/00000000000000007735a844/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/c2cf88/00000000000000007735a847/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c2cf88/00000000000000007735a847/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c2cf88/00000000000000007735a847/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/b1d7bd/00000000000000007735a84a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b1d7bd/00000000000000007735a84a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b1d7bd/00000000000000007735a84a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/be96d8/00000000000000007735a84d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/be96d8/00000000000000007735a84d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/be96d8/00000000000000007735a84d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "urw-din-condensed";
  src: url("https://use.typekit.net/af/3f20e7/00000000000000007735a850/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/3f20e7/00000000000000007735a850/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/3f20e7/00000000000000007735a850/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

.tk-urw-din {
  font-family: "urw-din", sans-serif;
}
.tk-urw-din-condensed {
  font-family: "urw-din-condensed", sans-serif;
}

/*
  Above is a font import for logo
*/

@font-face {
  font-family: "CircularStd";
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local("CircularStd"), local("CircularStd-Book"),
    url("../assets/fonts/CircularStd/CircularStd-Book.ttf") format("truetype");
}

@font-face {
  font-family: "CircularStd";
  font-style: normal;
  font-display: auto;
  font-weight: 700;
  src: local("CircularStd"), local("CircularStd-Bold"),
    url("../assets/fonts/CircularStd/CircularStd-Bold.ttf") format("truetype");
}

.root * {
  font-family: "CircularStd";
}
