/* Search Bar / Autocomplete */
.react-autosuggest__container {
  position: relative;
}

@media (min-width: 700px) {
  .react-autosuggest__container {
    height: 48px;
  }
}

.react-autosuggest__input {
  height:100%;
  width: 100%;
  box-sizing: border-box;
  font-family: CircularStd;
  font-weight: 300;
  font-size: 16px;
  border-radius: 4px;
}


.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  box-sizing: border-box;
  top: 51px;
  width: 120%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}
.react-autosuggest__suggestions-container--open {
  width: 100%;
}


.react-autosuggest__section-title {
  padding: 10px 20px;
  font-weight: 600;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

/* Filter list (RefinementList) */
.ais-RefinementList {
  color: #21243d;
  font-size: 0.75rem;
}
.ais-RefinementList .ais-SearchBox-input {
  border-radius: 3px;
  color: rgba(33, 36, 61, 0.8);
  font-size: 0.8rem;
  min-height: 40px;
  padding: 0 44px;
}

.ais-RefinementList-item,
.ais-RefinementList-item *,
.ais-RatingMenu-item {
  cursor: pointer;
}

.ais-RefinementList-item {
  padding-bottom: 1rem;
}

.ais-RefinementList-item {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ais-RefinementList .ais-SearchBox-input {
  border-radius: 3px;
  color: rgba(33, 36, 61, 0.8);
  font-size: 0.8rem;
  min-height: 40px;
  padding: 0 44px;
}

.ais-RefinementList .ais-SearchBox-form {
  margin-bottom: 1rem;
}

.ais-RefinementList-label {
  align-items: center;
  display: flex;
}

.ais-RefinementList-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(65, 66, 71, 0.08);
  border: none;
  border-radius: 2px;
  height: 1rem;
  margin: 0;
  margin-right: 1rem;
  position: relative;
  width: 1rem;
}

.ais-RefinementList-item--selected {
  font-weight: bold;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox {
  background-color: #e2a400;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox::after {
  background-color: #fff;
  border-radius: 4px;
  content: "";
  height: 4px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-2px) translateY(-2px);
  width: 4px;
}

.ais-RefinementList-count {
  align-items: center;
  background-color: rgba(65, 66, 71, 0.08);
  border-radius: 4px;
  color: rgba(33, 36, 61, 0.8);
  display: flex;
  font-size: 0.64rem;
  font-weight: 600;
  letter-spacing: 1.1px;
  margin-left: 8px;
  padding: 0 4px;
}

.ais-RefinementList-showMore {
  margin-top: 1rem;
}

.ais-RefinementList-showMore{
  margin-top: 0;
}

.productCard {
  position: relative;
  width: 100%; 
 
}
.productCard:after {
  content: "";
  display: block;
  padding-bottom: 100%; 
}

.productCard img {
  position: absolute; 
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%; 
  height: 100%;
  object-fit: cover; 
  object-position: center;
}